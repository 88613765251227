import React, { useEffect, useState } from 'react'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'
import type { LinksFunction, MetaFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useNavigation,
  useRouteError,
} from '@remix-run/react'
import { GenericErrorBoundary } from '~/components/misc/error-boundary.tsx'

import { Toaster } from './components/ui/toaster.tsx'
//@ts-ignore
import styles from './root.css?url'
import calenderStyle from 'react-calendar/dist/Calendar.css?url'
import uiKitStyle from 'react-essentials-ui-kit/src/style/style.css?url'
import leafletStyle from 'leaflet/dist/leaflet.css?url'
import { createHead } from 'remix-island'
import util from './utils/util.ts'

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: calenderStyle },
  { rel: 'stylesheet', href: uiKitStyle },
  { rel: 'stylesheet', href: leafletStyle },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap',
  },
]

export const meta: MetaFunction = () => {
  return [
    { charset: 'utf-8' },
    { title: 'FR8 truck booking app' },
    { name: 'viewport', content: 'width=device-width,initial-scale=1' },
    {
      name: 'description',
      content:
        'We offer containers, open trucks and lorries at competitive rates. Enjoy the convenience of online truck booking!',
    },
  ]
}

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  /**
   * NOTE: `useLoaderData` is not available in the Error Boundary.
   * The loader likely failed to run so we have to do the best we can.
   */
  return (
    <Document>
      <GenericErrorBoundary />
    </Document>
  )
}

export const Head = createHead(() => (
  <>
    <Meta />
    <Links />
  </>
))

function Document(_props: { children: React.ReactNode; lang?: string }) {
  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV === 'prod') {
      // Load Google Tag Manager
      const gtmScript = document.createElement('script')
      gtmScript.innerHTML = `
        (function(w,d,s,l,i)
        {w[l]=w[l]||[];
        w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P3QC7JKN');
      `
      document.head.appendChild(gtmScript)

      // Load Microsoft Clarity
      const clarityScript = document.createElement('script')
      clarityScript.id = 'ms-clarity'
      clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "moogx7z4h0");
      `
      document.head.appendChild(clarityScript)

      // Add Google Tag Manager noscript
      const noscript = document.createElement('noscript')
      const iframe = document.createElement('iframe')
      iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-P3QC7JKN'
      iframe.height = '0'
      iframe.width = '0'
      iframe.style.display = 'none'
      iframe.style.visibility = 'hidden'
      noscript.appendChild(iframe)
      document.body.appendChild(noscript)
    }
  }, [])

  return (
    <>
      <Head />
      <Outlet />
      <ScrollRestoration />
      <Toaster />
      <Scripts />
    </>
  )
}

function App() {
  const location = useLocation()
  const navigation = useNavigation()
  const previousPathName = location?.pathname
  const [isInitialTransaction, setInitialTransaction] = useState(true)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentOrigin = window.location.origin
      const currentPathName = navigation?.location?.pathname || window.location.pathname

      const shouldUpdateTitle =
        isInitialTransaction || (currentPathName && currentPathName != previousPathName)

      if (shouldUpdateTitle) {
        util.setPageTitle(currentPathName, `${currentOrigin}${currentPathName}`)
        setInitialTransaction(false)
      }
    }
  }, [navigation.state])

  return (
    <Document>
      <Outlet />
    </Document>
  )
}
export default withSentry(App)
